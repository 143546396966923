<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <div class="content-wrap">
        <router-view :key="key" />
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style scoped>
.content-wrap {
  background: #fff;
}

.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
  overflow-y: scroll;
  background: rgb(234, 238, 247);
  padding: 20px;
  /*background: #fff;*/

  /*background: ;*/
}

.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
