<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import variables from '@/styles/variables.scss'
import _ from 'lodash'
import Logo from './Logo'
import SidebarItem from './SidebarItem'

export default {
  components: {
    SidebarItem,
    Logo
  },
  props: {
    showLogo: String,
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes() {
      const routeList = _.cloneDeep(this.$router.options.routes)

      let newChild
      _.each(routeList, (r) => {
        newChild = []
        _.each(r.children, (routeItem) => {
          if (_.get(routeItem, 'meta.menuId')) {
            //
            // if (routeItem.name !== 'job') {
            newChild.push(routeItem)
            // }
          }
        })
        r.children = newChild
      });

      return routeList
    },
    activeMenu() {
      const route = this.$route
      const {
        meta,
        path
      } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },

    variables() {
      return variables
    },
    isCollapse() {
      return false
      // return !this.sidebar.opened
    }
  }
}
</script>
