import Vue from 'vue';
import Router from 'vue-router';

/* Layout */
import Layout from '@/layout';

Vue.use(Router);

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    hidden: true,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: {
          title: '首页'
          // icon. 'dashboard'
        }
      },
      {
        path: '/changePwd',
        name: 'changePwd',
        component: () => import('@/views/changepwd/index'),
        hidden: true,
        meta: {
          title: '修改密码'
          // icon. 'dashboard'
        }
      }
    ]
  },

  {
    path: '/online',
    component: Layout,
    redirect: '/online/member',
    name: 'online',
    meta: {
      title: '线上业务'
      // icon. 'el-//icon.s-help'
    },
    children: [
      {
        path: 'member',
        component: () => import('@/views/online/member/index'),
        meta: {
          menuId: 10100000,
          title: '会员管理'
          // icon. 'table'
        }
      },
      {
        path: 'member/:id',
        hidden: true,
        component: () => import('@/views/online/member/details'),
        meta: {
          menuId: 10100000,
          title: '会员详情'
          // icon. 'table'
        }
      },
      {
        path: 'memberOrder',
        name: 'memberOrder',
        component: () => import('@/views/online/memberOrder/index'),
        meta: {
          menuId: 10200000,
          title: '会员订单'
          // icon. 'table'
        }
      },
      {
        path: 'recommendAward',
        name: 'recommendAward',
        component: () => import('@/views/online/recommendAward/index'),
        meta: {
          menuId: 10300000,
          title: '推荐奖励'
          // icon. 'table'
        }
      },
      {
        path: 'referralBonuses',
        name: 'referralBonuses',
        component: () => import('@/views/online/referralBonuses/index'),
        meta: {
          menuId: 10300000,
          title: '提现列表'
        }
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/online/info/index'),
        meta: {
          menuId: 10400000,
          title: '信息发布'
          // icon. 'table'
        }
      },
      {
        path: 'swiper',
        name: 'swiper',
        component: () => import('@/views/online/swiper/index'),
        meta: {
          menuId: 10400000,
          title: '轮播图管理'
          // icon. 'table'
        }
      },
      {
        path: 'job',
        name: 'job',
        component: () => import('@/views/online/job/index'),
        meta: {
          menuId: 10500000,
          title: '职位管理'
          // icon. 'table'
        }
      },

      {
        path: 'socialSecurity',
        name: 'socialSecurity',
        redirect: '/online/socialSecurity/cityConfig',
        component: () => import('@/views/default'),
        meta: {
          menuId: 10600000,
          title: '社保公积金'
          // icon. 'table'
        },
        children: [
          {
            path: 'cityConfig',
            name: 'cityConfig',
            component: () => import('@/views/online/socialSecurity/city/index'),
            meta: { title: '参保城市配置' }
          },
          {
            path: 'cityConfig/:id',
            name: 'cityConfigDetails',
            hidden: true,
            component: () => import('@/views/online/socialSecurity/city/details'),
            meta: { title: '参保城市配置' }
          },
          {
            path: 'socialSecurityTemplate',
            name: 'socialSecurityTemplate',
            component: () => import('@/views/online/socialSecurity/socialSecurityTemplate/index'),
            meta: { title: '社保模板' }
          },
          {
            path: 'socialSecurityTemplate/:id',
            name: 'socialSecurityTemplateDetails',
            hidden: true,
            component: () => import('@/views/online/socialSecurity/socialSecurityTemplate/details'),
            meta: { title: '社保模板' }
          },
          {
            path: 'providentFundTemplate',
            name: 'providentFundTemplate',
            component: () => import('@/views/online/socialSecurity/fund/index'),
            meta: { title: '公积金模板' }
          }
        ]
      },

      {
        path: 'official',
        name: 'official',
        component: () => import('@/views/default'),
        meta: {
          title: '官网管理',
          menuId: 10800000
          // icon. 'table'
        },
        children: [
          {
            path: 'customer',
            name: 'customer',
            component: () => import('@/views/online/official/customer/index.vue'),
            meta: { title: '合作客户' }
          },
          {
            path: 'header',
            name: 'header',
            component: () => import('@/views/online/official/banner/index.vue'),
            meta: { title: '首页横幅' }
          },
          {
            path: 'services',
            name: 'services',
            component: () => import('@/views/online/official/service/index.vue'),
            meta: { title: '服务介绍' }
          },
          {
            path: 'homenews',
            name: 'homenews',
            component: () => import('@/views/online/official/homenews/index.vue'),
            meta: { title: '行业资讯' }
          },
          {
            path: 'companyInfo',
            name: 'companyInfo',
            component: () => import('@/views/online/official/companyInfo/index.vue'),
            meta: { title: '公司信息' }
          },
          {
            path: 'company2',
            name: 'company2',
            component: () => import('@/views/online/official/company2/index.vue'),
            meta: { title: '公司介绍' }
          }
        ]
      }
    ]
  },

  {
    path: '/offline',
    component: Layout,
    redirect: '/offline/city',
    name: 'offline',
    meta: {
      title: '线下业务'
      // icon. 'el-//icon.s-help'
    },
    children: [
      {
        path: 'city',
        name: 'city',
        component: () => import('@/views/offline/city/index'),
        meta: {
          menuId: 20100000,
          title: '缴纳城市'
          // icon. 'table'
        }
      },
      {
        path: 'companyAdmin',
        name: 'companyAdmin',
        component: () => import('@/views/offline/manager/index'),
        meta: {
          menuId: 20500000,
          title: '管理员管理'
          // icon. 'table'
        }
      },
      {
        path: 'systemAdmin',
        name: 'systemAdmin',
        component: () => import('@/views/offline/systemAdmin/index'),
        meta: {
          menuId: 30200000,
          title: '企业管理员'
          // icon. 'table'
        }
      },
      {
        path: 'role',
        name: 'offLineRole',
        component: () => import('@/views/offline/role/index'),
        meta: {
          menuId: 20300000,
          title: '角色权限'
          // icon. 'table'
        }
      },
      {
        path: 'role/:id',
        name: 'offLineRoleDetails',
        component: () => import('@/views/offline/role/details'),
        hidden: true,
        meta: {
          title: '角色权限'
          // icon. 'table'
        }
      },
      {
        path: 'examine/:page',
        name: 'examine',
        component: () => import('@/views/offline/examine/index'),
        meta: {
          menuId: 20400000,
          title: '审批流程'
          // icon. 'table'
        }
      },
      {
        path: 'examine/details/:id',
        name: 'examineDetails',
        component: () => import('@/views/offline/examine/details'),
        hidden: true,
        meta: {
          title: '审批流程'
          // icon. 'table'
        }
      }
      // {
      //   path: 'enterpriseAdmin',
      //   name: 'enterpriseAdmin',
      //   component: () => import('@/views/online/member/index'),
      //   meta: {
      //     title: '企业管理员',
      //     //icon. 'table'
      //   }
      // },
    ]
  },

  {
    path: '/system',
    component: Layout,
    redirect: '/system/city',
    name: 'system',
    meta: {
      title: '系统设置'
      // icon. 'el-//icon.s-help'
    },
    children: [
      {
        path: 'city',
        name: 'systemCity',
        component: () => import('@/views/system/city/index'),
        meta: {
          menuId: 30300000,
          title: '城市管理'
          // icon. 'table'
        }
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/system/account/index'),
        meta: {
          menuId: 30100000,
          title: '系统账号'
          // icon. 'table'
        }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/system/systemRole/index'),
        meta: {
          menuId: 30200000,
          title: '系统角色'
          // icon. 'table'
        }
      }

      // {
      //   path: 'city',
      //   name: 'systemCity',
      //   component: () => import('@/views/online/member/index'),
      //   meta: {
      //     title: '城市管理',
      //     //icon. 'table'
      //   },
      // },
    ]
  },

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
