import request from '@/utils/request'

// 查询城市社保公积金配置列表
export function listConfig(query) {
  return request({
    url: '/config/list',
    method: 'get',
    params: query
  })
}

// 查询城市社保公积金配置详细
export function getConfig(id) {
  return request({
    url: `/config/info/${id}`,
    method: 'get'
  })
}

// 新增城市社保公积金配置
export function addConfig(data) {
  return request({
    url: '/config/save',
    method: 'post',
    data
  })
}

// 修改城市社保公积金配置
export function updateConfig(data) {
  return request({
    url: '/config/update',
    method: 'post',
    data
  })
}

// 删除城市社保公积金配置
export function delConfig(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/config/delete',
    method: 'post',
    data
  })
}

// 导出城市社保公积金配置
export function exportConfig(query) {
  return request({
    url: '/config/export',
    method: 'get',
    params: query
  })
}
