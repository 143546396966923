import axios from 'axios';
import _ from 'lodash';
import {
  Notification, MessageBox, Message, Loading
} from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

const time = 800;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API || '/hr-admin',
  // 超时
  timeout: 10000
});

let loadingInstance;

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (config.url === '/sys/oss/upload') {
      loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
    // if()

    if (getToken()) {
      config.headers.Authorization = `Bearer ${getToken()}`; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error);
    loadingInstance && loadingInstance.close();
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    loadingInstance && loadingInstance.close();
    if (res.status !== 200) {
      Message.error({
        message: `网络请求异常${res.statusText}`,
        duration: time
      });
      return Promise.reject('error');
    }
    const { code } = res.data;
    if (`${code}` === '300') {
      Message.error({
        message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
        duration: time
      });
      setTimeout(() => {
        window.localStorage.clear();
        window.location.replace('/login');
      }, time);
    } else if (`${code}` !== '200') {
      Message.error({
        message: _.get(res.data, 'msg', '未知异常,f2e'),
        duration: time
      });
      return Promise.reject('error');
    } else {
      if (undefined !== res.data.data && res.data.data != null) {
        // 为了防止精度丢失，后台返回的数值字段，全部被转换成了字符型，所以分页的参数，要重新从字符转换成数值
        if (undefined !== res.data.data.total && res.data.data.total != null) {
          res.data.data.total = parseInt(res.data.data.total);
        }
        if (undefined !== res.data.data.size && res.data.data.size != null) {
          res.data.data.size = parseInt(res.data.data.size);
        }
        if (undefined !== res.data.data.current && res.data.data.current != null) {
          res.data.data.current = parseInt(res.data.data.current);
        }
      }

      if (_.has(res.data, 'page')) {
        res.data.data = res.data.page;
      }

      return res.data;
    }
  },
  (error) => {
    console.log(`err${error}`);
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    loadingInstance && loadingInstance.close();
    return Promise.reject(error);
  }
);

export default service;
